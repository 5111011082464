<template>
  <div>
    <EuiContainer style="height: calc(100vh - 180px);">
      <EuiGrid gutters class="eui-u-mb-20">
        <EuiGridRow>
          <EuiGridColumn>
            <div class="flex items-center">
              <EuiButton variant="text" iconOnly size="large" color="primary" @click="cancel">
                <EuiIcon name="chevron_left"/>
              </EuiButton>
              <EuiHeading :level="1"><b>{{ title }}</b></EuiHeading>
            </div>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <signatory ref="signatory" :signatory="{ signatory }" :options="{ options }"></signatory>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn v-if="canSelectArchivalOrgs">
            <linked-organizations :localSignatoryId="this.$route.params.signId" v-on:affectSignatoryToOrg="affectSignatoryToOrganisations"></linked-organizations>
          </EuiGridColumn>
          <EuiGridColumn></EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </EuiContainer>
    <EuiBottomBar :class="bottomBarStyle">
      <EuiButton color="primary" @click="cancel">{{$t('button.cancel')}}</EuiButton>
      <EuiButton color="primary" variant="raised" @click="updateSignatory" :disabled="haveSameProperties && createOrgList.length === 0 && deleteOrgList.length === 0">{{$t('button.confirm')}}</EuiButton>
    </EuiBottomBar>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';
import DOMpurify from 'dompurify';
import LinkedOrganizations from '../../linkedorganizations/LinkedOrganizations';
import Signatory from '../Signatory';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'signatories.external.edit',
  data() {
    return {
      title: '',
      tmpSignatory: {},
      signatory: {
        entityType: 'person',
        firstname: '',
        lastname: '',
        email: '',
        mobilePhone: '',
        address: '',
        postalCode: '',
        town: '',
        logo: undefined,
        login: '',
        password: '',
        function: '',
        service: '',
        reference: '',
        userId: '',
      },
      searchParams: {
        orders: [{ fieldName: 'fullname', orderBy: 'DESC' }],
        params: [],
        offset: 0,
        limit: 20,
      },
      createOrgList: [],
      deleteOrgList: [],
    };
  },
  watch: {
    signId(value) {
      this.fetchSignatory(value);
    },
  },
  props: {
    signId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState({
      isSidenavOpen: (state) => state.ModuleEdocSign.ui.isSidenavOpen,
      isMiniVariant: (state) => state.ModuleEdocSign.ui.isMiniVariant,
      isMobileView: (state) => state.application.responsive.xs,
    }),
    ...mapGetters({
      storeSignatories: 'ModuleEdocSign/signatory/signatories',
      storeSearchParams: 'ModuleEdocSign/signatory/searchParams',
      options: 'ModuleEdocSign/auth/options',
    }),
    canSelectArchivalOrgs() {
      if (this.$store.state.ModuleEdocSign.auth.profile.options && this.$store.state.ModuleEdocSign.auth.profile.options.selectCustomerOrgsArchival) {
        return this.$store.state.ModuleEdocSign.auth.profile.options.selectCustomerOrgsArchival.value === '1';
      }
      return true;
    },
    bottomBarStyle() {
      return {
        '-mini': !this.isMobileView && this.isSidenavOpen && this.isMiniVariant,
        '-default': this.isSidenavOpen && !this.isMiniVariant,
      };
    },
    haveSameProperties() {
      return isEqual(this.tmpSignatory, this.signatory);
    },
  },
  methods: {
    ...mapActions({
      setLastBreadcrumb: 'application/setLastBreadcrumb',
      storeUpdateSignatory: 'ModuleEdocSign/signatory/updateSignatory',
      getSignatory: 'ModuleEdocSign/signatory/getSignatory',
      addToLinkedOrganizations: 'ModuleEdocSign/organizations/addSignatoryToOrganisations',
      delFromLinkedOrganizations: 'ModuleEdocSign/organizations/removeSignatoryFromOrganisations',
      pushAlert: 'application/pushAlert',
    }),
    cancel() {
      this.$router.push({ name: 'signatories.external.index' });
    },
    updateSignatory() {
      if (this.$refs.signatory.validateFormFields()) {
        // sanitize
        this.signatory.firstname = DOMpurify.sanitize(this.signatory.firstname);
        this.signatory.lastname = DOMpurify.sanitize(this.signatory.lastname);
        // send to back-end
        this.storeUpdateSignatory(this.signatory).then(() => {
          this.updateLinkedOrganizations(this.$route.params.signId);
          this.toSignatories();
        });
      } else {
        this.pushAlert({
          message: this.$i18n.t('settings.groups.edit.error.phoneInvalid'),
          type: 'error',
        });
      }
    },
    affectSignatoryToOrganisations(orgzObj) {
      this.createOrgList = orgzObj.localOrgs.filter((org) => !orgzObj.fetchedOrgs.includes(org));
      this.deleteOrgList = orgzObj.fetchedOrgs.filter((org) => !orgzObj.localOrgs.includes(org));
    },
    toSignatories() {
      this.$router.push({ path: '/signatories/external' });
    },
    updateLinkedOrganizations(signId) {
      this.addToLinkedOrganizations({
        organizationIds: this.createOrgList,
        user: {
          userId: signId,
        },
      });
      this.delFromLinkedOrganizations({
        organizationIds: this.deleteOrgList,
        user: {
          userId: signId,
        },
      });
    },
    fetchSignatory(userId) {
      this.setLastBreadcrumb('');
      this.getSignatory({ userId })
        .then((response) => {
          if (response) {
            this.setLastBreadcrumb(response.fullname);
            this.signatory = cloneDeep(response);
            this.tmpSignatory = cloneDeep(response);
            this.title = response.firstname + ' ' + response.lastname;
          }
        });
    },
  },
  mounted() {
    this.fetchSignatory(this.signId);
  },
  components: {
    Signatory,
    LinkedOrganizations,
  },
};
</script>

<style scoped>
  .withNavbar {
    position: fixed;
    left: 75px;
    width: calc(100% - 75px);
    min-height: 100%;
    background-color: #f4f7fd;
    padding-top: 10px;
  }

  .headingEdit > *{
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    vertical-align: top;
    line-height: 50px;
    margin-top: 0;
    padding-top: 0;
  }

  .headingEdit > .router-link-active {
    margin-top: 3px;
  }

  .footerEdit {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 40px;
    text-align: right;
    background-color: white;
  }
</style>
